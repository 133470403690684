<template>
  <InputText :type="type" :placeholder="label" class="input" />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Input",
    },
    type: {
      type: String,
      default: "text",
    },
  },
};
</script>

<style scoped>
.input {
  width: 350px;
  height: 50px;
  padding: 1rem;
  border-radius: 10px !important;
  border: none !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  box-shadow: 0 0 20px rgba(35, 141, 130, 0.15) !important;
  margin-bottom: 0.5rem;
}
.input::placeholder {
  color: var(--primary) !important;
}
</style>
