<template>
  <div>
    <BasicLogo width="300" />
    <div class="content-title">
      <SeparatorTitle title="Nueva contraseña" :separator="false" />
    </div>
    <BasicSubtitle
      title="Es necesario que genere una nueva contraseña para ingresar al sistema."
      subtitle="La contraseña debe tener entre 8 y 15 caracteres e incluir al menos una letra minúscula, una letra mayúscula, un dígito y un caracter especial. La contraseña no debe tener espacios en blanco."
    />
    <div class="content-form">
      <BasicInput
        v-model="form.password"
        label="Nueva contraseña *"
        type="password"
        @keyup.enter="onChange"
      />
      <FormError
        :show="formError.password"
        message="La contraseña debe cumplir todos los requisitos"
      />
      <BasicInput
        v-model="form.confirmPassword"
        label="Repita la contraseña *"
        type="password"
        @keyup.enter="onChange"
      />
      <FormError
        :show="formError.confirmPassword"
        message="Las contraseñas no coinciden"
      />
    </div>
    <div class="content-submit">
      <PrimaryButton label="Enviar" :click="onChange" />
    </div>
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo";
import BasicInput from "../../widgets/input/BasicInput.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton.vue";
import SeparatorTitle from "../../widgets/title/SeparatorTitle.vue";
import FormError from "../../widgets/error/FormError.vue";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle.vue";
import { reactive, watchEffect } from "vue";
import store from "../../store";
import { useRouter } from "vue-router";
import { validation } from "../../constants";

export default {
  components: {
    BasicLogo,
    BasicInput,
    PrimaryButton,
    SeparatorTitle,
    FormError,
    BasicSubtitle,
  },
  setup() {
    const router = useRouter();
    const form = reactive({
      password: "",
      confirmPassword: "",
    });
    const formError = reactive({
      password: false,
      confirmPassword: false,
    });

    watchEffect(() => {
      if (form.password && validation.passwordRegex.test(form.password))
        formError.password = false;
      if (form.confirmPassword && form.password === form.confirmPassword)
        formError.confirmPassword = false;
    });

    const validate = () => {
      let valid = true;

      if (!form.password || !validation.passwordRegex.test(form.password)) {
        formError.password = true;
        valid = false;
      }
      if (!form.confirmPassword || form.password !== form.confirmPassword) {
        formError.confirmPassword = true;
        valid = false;
      }

      return valid;
    };

    const onChange = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);
      const response = await store.dispatch("AUTH_CHANGE", {
        password: form.password,
      });
      store.commit("setLoading", false);
      if (response.ok) {
        store.state.toast.add({
          severity: "success",
          summary: "",
          detail: response.message,
          life: 5000,
        });
        const user = store.state.auth.user;
        user.indCreated = false;
        store.commit("setUser", user);
        router.push("/home");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    return { form, formError, onChange };
  },
};
</script>

<style scoped>
.content-title {
  margin-top: 4rem;
}
.content-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
}
.content-submit {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
</style>
