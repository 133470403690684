<template>
  <div class="logo-container">
    <img :width="width" :src="src" :alt="alt" class="logo" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  props: {
    width: {
      type: String,
      default: "345",
    },
    id: {
      type: String,
      default: "tuappto-logo",
    },
    src: {
      type: String,
      default: require("../../assets/images/logoPrincipalClaim.png"),
    },
    alt: {
      type: String,
      default: "Logo TuAppto",
    },
  },
};
</script>

<style scoped>
.logo-container {
  text-align: center;
}
</style>
